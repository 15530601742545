import { QuestionForm, FormField, Input } from 'src/components';
import { MarkdownFragment, TFunction, useTranslations } from 'src/renderless/useTranslations';
import { useAnalyticsErrorEvents } from 'src/monitoring/analyticsHooks';
import { UserInputsType, useUserInputs } from 'src/renderless/useUserInputs';
import { ValidationRule } from 'src/validation';
import { FieldEnums } from './types';
import { validate, validateAll } from './validation';
import { useGuarantidUserStore } from 'src/renderless/useGuarantidUserStore';

const cmsLocation = 'introduce-yourself';
const useContent = (t: TFunction) => ({
  header: t(`${cmsLocation}.header`),
  email: {
    label: t(`${cmsLocation}.email.label`),
    placeholder: t(`${cmsLocation}.email.placeholder`) as string,
    description: t(`${cmsLocation}.email.description`),
  },
  firstName: {
    label: t(`${cmsLocation}.firstName.label`),
    placeholder: t(`${cmsLocation}.firstName.placeholder`) as string,
  },
  lastName: {
    label: t(`${cmsLocation}.lastName.label`),
    placeholder: t(`${cmsLocation}.lastName.placeholder`) as string,
  },
  policy: t(`${cmsLocation}.policy`),
  nextButton: t(`next`),
});

interface Props {
  onSubmit: (values: Partial<UserInputsType>) => void;
}

const IntroduceYourselfForm = ({ onSubmit }: Props) => {
  const { t } = useTranslations();
  const content = useContent(t);
  const { values, updateValues } = useUserInputs();
  const [errors, setErrors] = useAnalyticsErrorEvents(FieldEnums);
  const { createUser } = useGuarantidUserStore();

  const handleChange = (key: FieldEnums) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (errors[key] && !validate(key, value)) {
      setErrors({ ...errors, [key]: undefined });
    }
    updateValues({ [key]: value });
  };

  const handleBlur = (key: FieldEnums) => () => {
    const validationResult = validate(key, values[key]);
    if (validationResult) {
      setErrors({ ...errors, [key]: t(validationResult.errorMessage) });
    } else if (errors[key]) {
      setErrors({ ...errors, [key]: undefined });
    }
  };

  const handleSubmit = () => {
    const validationResults = validateAll({
      [FieldEnums.email]: values[FieldEnums.email],
      [FieldEnums.firstName]: values[FieldEnums.firstName],
      [FieldEnums.lastName]: values[FieldEnums.lastName],
    });

    if (Object.keys(validationResults).length > 0) {
      setErrors(
        Object.keys(validationResults).reduce(
          (res, cur) => ({
            ...res,
            [cur]: t(
              (validationResults[cur as keyof typeof validationResults] as ValidationRule)
                .errorMessage,
            ),
          }),
          {},
        ),
      );
    } else {
      createUser({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
      });
      onSubmit(values);
    }
  };

  return (
    <QuestionForm
      className="mt-48 lg:mt-0"
      headerText={content.header as string}
      onSubmit={handleSubmit}
      data-testid="introduce-yourself-form"
    >
      <FormField
        label={content.email.label}
        error={errors[FieldEnums.email]}
        description={content.email.description}
        data-testid={FieldEnums.email}
      >
        <Input
          data-testid="email-input"
          autoComplete="email"
          type="email"
          value={values[FieldEnums.email]}
          placeholder={content.email.placeholder}
          onChange={handleChange(FieldEnums.email)}
          onBlur={handleBlur(FieldEnums.email)}
          error={!!errors[FieldEnums.email]}
          aria-describedby="description"
        />
      </FormField>

      <FormField
        label={content.firstName.label}
        error={errors[FieldEnums.firstName]}
        data-testid={FieldEnums.firstName}
      >
        <Input
          data-testid="first-name-input"
          autoComplete="given-name"
          value={values[FieldEnums.firstName]}
          placeholder={content.firstName.placeholder}
          onChange={handleChange(FieldEnums.firstName)}
          onBlur={handleBlur(FieldEnums.firstName)}
          error={!!errors[FieldEnums.firstName]}
        />
      </FormField>

      <FormField
        label={content.lastName.label}
        error={errors[FieldEnums.lastName]}
        data-testid={FieldEnums.lastName}
      >
        <Input
          data-testid="last-name-input"
          autoComplete="family-name"
          value={values[FieldEnums.lastName]}
          placeholder={content.lastName.placeholder}
          onChange={handleChange(FieldEnums.lastName)}
          onBlur={handleBlur(FieldEnums.lastName)}
          error={!!errors[FieldEnums.lastName]}
        />
      </FormField>
      <MarkdownFragment className="text-sm">{content.policy as string}</MarkdownFragment>
    </QuestionForm>
  );
};

export default IntroduceYourselfForm;
