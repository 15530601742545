import { QuestionTemplate, QuestionAside, Card, IconList } from 'src/components';
import { BadgeCheck, LightningBolt, PiggyBank, UserGroup, ShieldLock } from 'src/icons';
import { MarkdownFragment, TFunction, useTranslations } from 'src/renderless/useTranslations';
import { useStepsRouter } from 'src/renderless/useStepsRouter';
import IntroduceYourselfForm from './introduceYourselfForm';

const cmsLocation = 'introduce-yourself';
const useContent = (t: TFunction) =>
  ({
    title: t(`${cmsLocation}.title`),
    description: t(`${cmsLocation}.description`),
    icon0: t(`${cmsLocation}.icon0`),
    icon1: t(`${cmsLocation}.icon1`),
    icon2: t(`${cmsLocation}.icon2`),
    icon3: t(`${cmsLocation}.icon3`),
  } as { [key: string]: string });

const IntroduceYourself = () => {
  const { t } = useTranslations();
  const content = useContent(t);
  const { goNext } = useStepsRouter();

  return (
    <>
      <QuestionTemplate title={content.title} activeCrumb={0}>
        <QuestionAside
          className="h-[308px]"
          headerText={content.title}
          description={<MarkdownFragment>{content.description}</MarkdownFragment>}
        >
          <Card className="xl:grid xl:grid-cols-4 col-span-full lg:col-span-4">
            <IconList
              className="xl:col-span-3"
              listItems={[
                {
                  icon: BadgeCheck,
                  description: content.icon0,
                },
                {
                  icon: LightningBolt,
                  description: content.icon1,
                },
                {
                  icon: PiggyBank,
                  description: content.icon2,
                },
                {
                  icon: UserGroup,
                  description: content.icon3,
                },
              ]}
            />
          </Card>
        </QuestionAside>
        <IntroduceYourselfForm onSubmit={goNext} />
      </QuestionTemplate>
    </>
  );
};

export default IntroduceYourself;
