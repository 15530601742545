import { useRouter } from 'next/router';
import { useAnalyticsEvent } from 'src/monitoring/analyticsHooks';

const routesMap: { [x: string]: { next: string } } = {
  '/apply/you': { next: '/apply/property' },
  '/apply/property': { next: '/apply/income' },
  '/apply/income': { next: '/apply/contact' },
  '/apply/contact': { next: '/decision' },
} as const;

export const useStepsRouter = () => {
  const router = useRouter();
  const { pathname, query } = router;
  const { broadcastEvent } = useAnalyticsEvent();

  const goNext = () => {
    const currentRoute = routesMap[pathname];
    if (currentRoute && currentRoute.next) {
      broadcastEvent(`Clicked next to ${currentRoute.next}`);
      router.push({ pathname: currentRoute.next, query });
    }
  };

  const goBack = () => {
    broadcastEvent(`Clicked back on ${pathname}`);
    router.back();
  };

  return {
    goNext,
    goBack,
  };
};
